@tailwind base;
@tailwind components;
@tailwind utilities;
.topnav {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  color: white;
}

.topnav .icon {
  display: none;
}
.topnav .icon2 {
  display: none;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;display: block; width: 100%}
  .topnav.responsive .icon {
     display: none;
  }
  .topnav.responsive .icon2 {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

.arrow {
  border: solid rgb(82 82 91);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: 0.5s;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
